import React, { Suspense, useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "./Utils/Loader";
import Layout from "./components/Layout/Layout";
import "./App.css";

const Navbar = React.lazy(() => wait(1000).then(()=> import("./components/Navbar/Navbar")));
const Home = React.lazy(() => wait(1000).then(()=> import("./pages/Home/Home")));
const Trade = React.lazy(() => wait(1000).then(()=> import("./pages/Trade/Trade")));
const Earn = React.lazy(() => wait(1000).then(()=> import("./pages/Earn/Earn")));
const Dao = React.lazy(() => wait(1000).then(()=> import("./pages/DAO/Dao")));
const Migration = React.lazy(() => wait(1000).then(()=> import("./pages/Migration/Migration")));



const wait = (time) => {
  return new Promise(resolve =>{
    setTimeout(()=>{
      resolve()
    }, time)
  })
}


function App() {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(delay);
  // }, []);


  return (
    <>
      <Suspense fallback={<Loader />}>
          <div className="hom_wrraper">
            <Routes>
              <Route element={<Layout />}>
              <Route exact path='/' element={<Navigate to={'/home'} />} />
                <Route path={"/"} element={<Home />} />
                <Route path={"/home"} element={<Home />} />
                <Route path={"/trade"} element={<Trade />} />
                <Route path={"/earn"} element={<Earn />} />
                <Route path={"/dao"} element={<Dao />} />
                <Route path={"/migration"} element={<Migration />} />
              </Route>
            </Routes>
          </div>
      </Suspense>
    </>
  );
}

export default App;




