import React, { useEffect, useState } from 'react'
import NavBar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { Outlet } from 'react-router-dom'
import './Layout.css'

export default function Layout() {
    const [scrollHeight, setScrollHeight] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            setScrollHeight(scrollPosition > 5);
        }
        if (typeof window !== 'undefined') {
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    return (
        <>
            <div className={`nav_section_lot ${scrollHeight && 'background_color'}`}>
                <NavBar />
            </div>
            <div className='ouotlet-layout'>
                <Outlet />
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}
