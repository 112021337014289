import React, { useEffect, useRef, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import telegramIcon from '../../assests/telegramIcon.png'
import magnumLogo from '../../assests/logo.webp'
import './Navbar.css'
import { ButtonWrapper } from '../Buttons';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function NavBar() {

    const [togalIcon, setTogalIcon] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();

    const handleToggleIcon = () => {
        setTogalIcon(prev => !prev);
        setExpanded(expanded ? false : "expanded");
    }

    const handleCollapseNav = () => {
        // setExpanded(false);
        // setTogalIcon(true);
    }

    useEffect(() => {
        // handleCollapseNav()
    }, [location.pathname]);

    return (
        <>
            <div className='nav-Top'>
                <button className='nav-Top-Button'> <span className=' d-flex align-items-center d-sm-block d-none'> Our only verified <RiVerifiedBadgeFill size={20} /> Telegram handle:</span> Join Telegram Community
                    <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://t.me/magnummeta"><img src={telegramIcon} alt="" width={20} /></a>
                </button>
            </div>
            <div className='navbar_main container-xxl'>
                <Navbar expand="lg" expanded={expanded} className="bg-body-tertiary nav-bar-typo">
                    <Container className='px-sm-1 px-0'>
                            <Navbar.Brand as={Link} to="/home">
                                <img src={magnumLogo} alt="magnum logo" width={'220px'} loading='lazy' />
                            </Navbar.Brand>
                            <Navbar.Toggle onClick={handleToggleIcon} aria-controls="navbarScroll" >
                                {togalIcon ? <RxHamburgerMenu className='abcxyz' fontSize={35} color='#E84427' /> : <RxCross2 className='abcxyz' color='#E84427' fontSize={40} />}
                            </Navbar.Toggle>
                        <Navbar.Collapse className="justify-content-evenly x" id="navbarScroll">
                            <Nav className="mx-auto my-2 my-lg-0 nav-links nav-items-head" style={{ maxHeight: '150px' }} navbarScroll>
                                <NavLink onClick={handleCollapseNav} to="/trade" className="nav-link" activeClassName="active-link">Trade</NavLink>
                                <NavLink onClick={handleCollapseNav} to="/earn" className="nav-link" activeClassName="active-link">Earn</NavLink>
                                <NavLink onClick={handleCollapseNav} to="/dao" className="nav-link" activeClassName="active-link">DAO</NavLink>
                                <NavLink onClick={handleCollapseNav} to="/migration" className="nav-link" activeClassName="active-link">Migration</NavLink>
                            </Nav>
                            <ButtonWrapper className='lunch-app' style={{ width: '193px', height: '50px' }}> <a target='_blank' rel="noopener noreferrer" href="https://app.magnummeta.xyz/home">Launch App</a> </ButtonWrapper>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}
